// components/FavoritesLoader.tsx
import { useEffect } from 'react';
import { isBefore, isDate, subMinutes } from 'date-fns';
import { useSession } from 'next-auth/react';
import { useFavoritesStore } from '@src/stores/favorites';
import { useLoginStore } from '@src/stores/login';

export const FavoritesSettingsHandler = () => {
  const { status } = useSession();
  const { fetchUserSettings } = useLoginStore((state) => ({
    fetchUserSettings: state.fetchUserSettings,
  }));
  const { lastFetched, fetchFavorites, handleTempFavorites, clearFavorites } = useFavoritesStore((state) => ({
    favorites: state.favorites,
    lastFetched: state.lastFetched,
    fetchFavorites: state.fetchFavorites,
    handleTempFavorites: state.handleTempFavorites,
    clearFavorites: state.clearFavorites,
  }));

  useEffect(() => {
    if (
      (status === 'authenticated' && (!lastFetched || !isDate(lastFetched))) ||
      isBefore(lastFetched, subMinutes(new Date(), 10))
    ) {
      handleTempFavorites(fetchFavorites);
      fetchUserSettings();
    }

    if (status === 'unauthenticated') {
      clearFavorites();
    }
  }, [clearFavorites, fetchFavorites, lastFetched, status]);
  return null;
};
