import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';

export const Toasts = () => {
  return (
    <div>
      <ToastContainer
        position="bottom-center"
        autoClose={4000}
        stacked
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="dark"
        icon={false}
        transition={Slide}
      />
    </div>
  );
};
