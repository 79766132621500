import React, { useEffect, useMemo, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import type { AppProps } from 'next/app';
import App from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { SettingsProvider, GoogleTagManager } from '@vgn-medien-holding/vgn-fe-components';
import { restoreSsrCache, SessionProvider, UrqlProvider } from '@vgn-medien-holding/vgn-gql-shared-fe-queries';
import * as Fathom from 'fathom-client';
import { Session } from 'next-auth';
import 'overlayscrollbars/overlayscrollbars.css';
import packageInfos from 'package.json';
import { client, ssrCache, ssrQuery } from 'src/lib/graphql/urql';
import { Toasts } from '@components/molecules/Toasts/Toasts';
import { FavoritesSettingsHandler } from '@components/organisms/Authentication/FavoritesSettings/FavoritesSettingsHandler';
import { OverlayLoginFlow } from '@components/organisms/Authentication/OverlayLoginFlow/OverlayLoginFlow';
import { Empty } from '@components/templates/layouts/Empty';
import { Magazine, Navigation } from '@src/lib/graphql/generated';
import { settings } from '@src/settings/settings';
import '@src/styles/globals.css';
import AppContext from '@lib/AppContext';
import { useScript } from '@lib/hooks/useScript';

const appVersion = packageInfos?.version;

const navHandles = {
  header: process.env.NEXT_PUBLIC_HEADER_NAV_HANDLE,
  footer: process.env.NEXT_PUBLIC_FOOTER_NAV_HANDLE,
};

type PagePropsType = {
  navigations: { [key: string]: Navigation };
  magazines: Magazine[];
  errorStatus?: number;
  gtmId: string;
  otId: string;
  session: Session;
};

type ComponentWithPageLayout = AppProps<PagePropsType> & {
  Component: AppProps['Component'] & {
    PageLayout?: React.ComponentType;
  };
};

function MyApp({ Component, pageProps }: ComponentWithPageLayout) {
  const { otId, gtmId } = pageProps;
  const [searchVisible, setSearchVisible] = useState(false);
  const Layout = Component.PageLayout || Empty;
  const [containerPolyfillShouldLoad, setContainerPolyfillShouldLoad] = useState(false);
  const router = useRouter();

  restoreSsrCache({ pageProps, ssrCache });

  useEffect(() => {
    console.info(
      `%cTV-Media v${appVersion}`,
      `width: 100vw; display: block; background: linear-gradient(to bottom right, #FF0000, #550000); color: #fff; padding: 0.5rem 1.5rem ; margin: 1.5rem 2.5rem 2.5rem 0; border-radius: 3rem;`,
    );
    setContainerPolyfillShouldLoad(!('container' in document.documentElement.style));
    Fathom.load('TPPJWIUN', {
      //includedDomains: ['www.tv-media.at'],
    });

    function onRouteChangeComplete() {
      Fathom.trackPageview();
    }
    // Record a pageview when route changes
    router.events.on('routeChangeComplete', onRouteChangeComplete);

    // Unassign event listener
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useScript(`https://cdn.jsdelivr.net/npm/container-query-polyfill@1/dist/container-query-polyfill.modern.js`, {
    removeOnUnmount: false,
    shouldPreventLoad: !containerPolyfillShouldLoad,
  });

  const providerValues = useMemo(
    () => ({
      header: {
        navigation: pageProps.navigations.header,
      },
      search: {
        searchVisible: searchVisible,
        setSearchVisible: setSearchVisible,
      },
      footer: {
        navigation: pageProps.navigations.footer,
      },
      magazines: pageProps.magazines,
    }),
    [pageProps.magazines, pageProps.navigations.footer, pageProps.navigations.header, searchVisible],
  );

  return (
    <SessionProvider session={pageProps.session}>
      <AppContext.Provider value={providerValues}>
        <SettingsProvider settings={settings}>
          <UrqlProvider value={client}>
            <FavoritesSettingsHandler />
            <Layout>
              {otId && <OneTrust otId={otId} />}
              {gtmId && <GoogleTagManager gtmId={gtmId} customSource={'https://data.tv-media.at/eeyeitdp.js?st='} />}
              <Component {...pageProps} statusCode={pageProps.errorStatus} />
              <OverlayLoginFlow />
              <Toasts />
            </Layout>
          </UrqlProvider>
        </SettingsProvider>
      </AppContext.Provider>
    </SessionProvider>
  );
}

MyApp.getInitialProps = async (appContext) => {
  const { res } = appContext.ctx;
  const appProps = await App.getInitialProps(appContext);
  if (appProps.pageProps?.errorStatus && res) {
    res.statusCode = appProps.pageProps.errorStatus;
  }

  const { GetMagazinesDocument, GetNavigationByHandlesDocument } = await import('@src/lib/graphql/generated');

  const handles = Object.values(navHandles).filter((i) => i) as string[];
  const { data: navData } = await ssrQuery({
    query: GetNavigationByHandlesDocument,
    variables: { handles },
  });

  const navigations = Object.entries(navHandles).map(([key, value]) => ({
    [key]: navData?.navigationByHandles
      ? navData?.navigationByHandles.find((nav) => nav?.handle === value) || null
      : null,
  }));

  const { data: magazinesData } = await ssrQuery({
    query: GetMagazinesDocument,
    variables: {
      context: 'tvmedia',
    },
  });

  return {
    pageProps: {
      navigations: Object.assign({}, ...navigations),
      errorStatus: res?.statusCode,
      magazines: magazinesData?.magazines,
      gtmId: process.env.NEXT_PUBLIC_GTM_ID || null,
      otId: process.env.NEXT_PUBLIC_ONETRUST_ID || null,
    },
  };
};

export default MyApp;

const OneTrust = ({ otId }: { otId: string }) => (
  <>
    <link rel="preconnect" href="https://cdn.cookielaw.org" />
    <link rel="preconnect" href="https://geolocation.onetrust.com" />
    <Script async={true} src={'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'} data-domain-script={otId} />
    <Script async={true} id={'ot-custom'}>{`function OptanonWrapper() { }`}</Script>
  </>
);
